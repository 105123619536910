<template>
<report-table>
  <template #thead v-if="rows.length">
    <tr>
      <th scope="col" rowspan="2">
        <span class=" svg-icon svg-icon-primary svg-icon-sm">
          <inline-svg src="/media/svg/icons/General/Other1.svg" />
        </span>
      </th>
      <th scope="col" rowspan="2">
        {{ $t('ORDERS.NUMBER') }}
        <span class=" svg-icon svg-icon-primary svg-icon-sm">
          <inline-svg src="/media/svg/icons/Navigation/Up-right.svg" />
        </span>
      </th>
      <th scope="col" rowspan="2">{{ $t('ORDER.START_DATE') }}</th>
      <th scope="col" rowspan="2">{{ $t('FORM.END_DATE') }}</th>
      <th scope="col" rowspan="2">
        {{ $t('ORDERS.CLIENT_NAME') }}
        <span class=" svg-icon svg-icon-primary svg-icon-sm">
          <inline-svg src="/media/svg/icons/Navigation/Up-right.svg" />
        </span>
      </th>
      <th scope="col" rowspan="2">{{ $t('ORDER.SUMMARY.PHONE') }}</th>
      <th scope="col" rowspan="2">{{ $t('FORM.BRANCH') }}</th>
      <th scope="col" rowspan="2">{{ $t('ORDER.ITEMS') }}</th>
      <template v-for="(cat) in rows[0].categories">
        <th scope="col" colspan="2" :key="cat.name">
          <router-link target="_blank" class="text-secondary"
                       :to="{ name: 'servicesCategoriesEdit', params:{id:cat.uuid} }"
          >
            {{ cat.name }}
          </router-link>
          <span class=" svg-icon svg-icon-primary svg-icon-sm">
            <inline-svg src="/media/svg/icons/Navigation/Up-right.svg" />
          </span>
        </th>
      </template>
      <th scope="col" rowspan="2">{{ $t('REPORTS.SERVICE_SUMS_BY_ORDERS.FINAL_COST') }}</th>
    </tr>
    <tr>
      <template v-for="(cat,j) in rows[0].categories">
        <th :key="cat.name">{{ $t('ORDER.SETUP.QUANTITY') }}</th>
        <th :key="cat.name+j">{{ $t('ORDER.SUMMARY.COST') }}</th>
      </template>
    </tr>
  </template>
  <template #tbody v-if="rows.length">
    <tr :key="i" v-for="(row, i) in rows">
      <td>{{ i+1 }}</td>
      <td>
        <router-link target="_blank" class="text-secondary"
                     :to="{ name:'companyOrdersEdit', params:{id: row.uuid} }"
        >
          {{ row.num }}
        </router-link>
      </td>
      <td>{{ dateFormat(row.date) }}</td>
      <td>{{ row.deadline || '---' }}</td>
      <td>
        <router-link target="_blank" class="text-secondary"
                     :to="{ name: 'companyClientsEdit', params:{id:row.clientUuid} }"
        >
          {{ row.clientName }}
        </router-link>
      </td>
      <td>{{ row.clientPhone }}</td>
      <td>{{ row.branchName || '---' }}</td>
      <td>{{ row.inventoryCount }}</td>
      {{ add(`inventoryCount`, row.inventoryCount) }}
      <template v-for="(cat,j) in row.categories">
        <td :class="`count-${j}`" :key="cat.name+i">{{ cat.count }}</td>{{ add(`count-${cat.name}`,cat.count) }}
        <td :class="`sum-${j}`" :key="cat.name+j+i">{{ cat.sumCostWithDiscount/100 }}</td>{{ add(`cost-${cat.name}`,cat.sumCostWithDiscount) }}
      </template>
      <td>{{ row.costWithDiscount/100 }}</td>{{ add(`costwd-total`,row.costWithDiscount) }}
    </tr>
    <tr class="text-primary">
      <td colspan="7">{{ $t('REPORTS.TOTAL') }}</td>
      <td>{{ stored(`inventoryCount`) }}</td>
      <template v-for="(cat, k) in rows[0].categories">
        <td :key="cat.name">{{ stored(`count-${cat.name}`) }}</td>
        <td :key="cat.name + k">{{ stored(`cost-${cat.name}`)/100 }}</td>
      </template>
      <td>{{ stored(`costwd-total`)/100 }}</td>
    </tr>

  </template>
  <template #tbody v-else>
    <tr>
      <td scope="col" class="text-center">{{ $t('REPORTS.NO_DATA') }}</td>
    </tr>
  </template>
</report-table>
</template>

<script>
import ReportTable from '../table/Table'
import { FORMAT_DATE } from '../../../helpers/validation'

export default {
  name: 'ServiceCategorySumsByOrdersTable',
  components: { ReportTable },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FORMAT_DATE,
      loopStorage: {},
    }
  },
  computed: {
    stored() {
      return (k) => {
        return this.loopStorage[k] || 0
      }
    },
  },
  watch: {
    rows() {
      this.resetLoopStorage()
    },
  },
  methods: {
    dateFormat(val) {
      return this.$moment(val).format(FORMAT_DATE)
    },
    add(k, v) {
      if (this.loopStorage[k] === undefined) {
        this.loopStorage[k] = 0
      }
      this.loopStorage[k] += v
    },
    get(k) {
      return this.loopStorage[k] || 0
    },
    resetLoopStorage() {
      this.loopStorage = {}
    },
  },
}
</script>

<style scoped>

</style>
