import { render, staticRenderFns } from "./serviceCategorySumsByOrders.vue?vue&type=template&id=1609370c&scoped=true&"
import script from "./serviceCategorySumsByOrders.vue?vue&type=script&lang=js&"
export * from "./serviceCategorySumsByOrders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1609370c",
  null
  
)

export default component.exports